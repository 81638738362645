<template>
  <div>
    <el-row>
      <el-col :span="1">
        <el-button @click="goBackToCustomization" class="ml-10" size="mini">
          <i class="el-icon-arrow-left"></i>
        </el-button>
      </el-col>
      <el-col :span="10">
        <el-input
          v-model="customization.label"
          placeholder="Enter customization label"
          class="title-input ml-1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <el-button
          type="primary"
          style="float: right"
          @click="saveCustomization"
          >{{ customization._id ? "Update" : "Save" }}</el-button
        >
      </el-col>
    </el-row>
    <div>
      <!-- <div style="margin: 10px">
        <el-button @click="goBack" v-if="alreadyConfigured == false">
          <i class="el-icon-back"></i>
          Back
        </el-button>
      </div> -->
      <el-tabs
        v-model="activeTab"
        style="margin: 10px 0px 0px 10px"
        type="border-card"
      >
        <el-tab-pane name="Buttons" label="Buttons">
          <el-button @click="resetButtons" size="mini"
            ><i class="fa fa-refresh"></i> Reset
          </el-button>
          <el-table
            :data="customization.buttons"
            border
            v-loading="tableLoading"
            v-if="!refresh"
          >
            <el-table-column prop="buttonName" label="Button Name" width="220">
            </el-table-column>
            <el-table-column label="Button Label" width="250">
              <template slot-scope="scope">
                <el-input v-model="scope.row.value"> </el-input>
              </template>
            </el-table-column>
            <el-table-column label="View Status" width="250">
              <template slot-scope="scope">
                <el-switch
                  active-text="Show"
                  inactive-text="Hide"
                  v-model="scope.row.visibility"
                  :disabled="
                    scope.row.buttonName !== 'Duplicate' &&
                    scope.row.buttonName !== 'Save & New' &&
                    scope.row.buttonName !== 'Send Notification' &&
                    scope.row.buttonName !== 'View' &&
                    scope.row.buttonName !== 'Delete' &&
                    scope.row.buttonName !== 'Edit' &&
                    scope.row.buttonName !== 'Attachments'
                  "
                  @change="updateDOM(scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              name="Is Primary"
              label="Primary Button"
              width="150"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.isPrimary"
                  :disabled="isPrimaryDisabled(scope.row)"
                  @change="handlePrimaryChange(scope.row)"
                ></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="activeLayout" label="Active Layout">
          <el-radio-group
            v-model="customization.active_layout"
            style="display: block"
            @change="updateDOM"
            v-if="!refresh"
          >
            <el-radio label="STANDARD">Standard</el-radio>
            <el-radio label="PROFILE">Profile</el-radio>
            <el-radio label="COVER">Cover</el-radio>
          </el-radio-group>
        </el-tab-pane>
        <el-tab-pane name="tabs" label="Tabs Configuration">
          <el-row>
            <el-col :span="15" style="margin-right: 10px">
              <el-row style="align-items: center">
                <el-col>
                  <h3 class="h3 f-weight-400 mt-0 d-flex align-center">
                    <span class="text pl-1">Tabs Configuration</span>
                  </h3>
                </el-col>
                <el-col>
                  <el-button
                    style="float: right"
                    size="mini"
                    @click="previewConfiguration"
                  >
                    <i class="el-icon-view"></i>
                    Preview
                  </el-button>
                  <el-button
                    @click="resetTabs"
                    size="mini"
                    style="float: right; margin-right: 5px"
                  >
                    <i class="fa fa-refresh"></i>
                    Reset
                  </el-button>
                </el-col>
              </el-row>
              <el-card>
                <div class="user-menu-container" mt-15>
                  <div
                    :class="
                      customization.tab_groups.length
                        ? 'sidemenu'
                        : 'scrollable px-4 '
                    "
                  >
                    <el-tree
                      v-if="customization.tab_groups.length && !refresh"
                      :data="customization.tab_groups"
                      :props="props"
                      :default-expand-all="true"
                      :indent="40"
                    >
                      <template
                        class="custom-tree-node"
                        slot-scope="{ node, data }"
                      >
                        <el-card shadow="never" class="each-item pt-0">
                          <h4 class="h4 f-weight-500 mt-0 d-flex align-center">
                            <span class="text pl-1"
                              >{{
                                data.label.length > 20
                                  ? data.label.slice(0, 20) + "..."
                                  : data.label
                              }}
                            </span>
                          </h4>
                          <div class="actions-btn float-right">
                            <el-button
                              type="text"
                              size="medium"
                              @click="moveToTop(data, node)"
                              :disabled="
                                topTabDisabled(node) || disableFeature(node)
                              "
                            >
                              <i class="el-icon-top yale-blue"></i>
                            </el-button>
                            <el-button
                              type="text"
                              size="medium"
                              @click="moveToBottom(data, node)"
                              :disabled="
                                bottomTabDisabled(node) || disableFeature(node)
                              "
                            >
                              <i class="el-icon-bottom yale-blue"></i>
                            </el-button>
                            <el-button
                              type="text"
                              size="medium"
                              @click="editTab(data, node)"
                              :disabled="disableFeature(node)"
                            >
                              <i class="el-icon-edit warning"></i>
                            </el-button>
                            <el-button
                              type="text"
                              size="medium"
                              @click="deleteTab(data, node)"
                              :disabled="disableFeature(node)"
                            >
                              <i class="el-icon-delete tomato"></i>
                            </el-button>
                          </div>
                        </el-card>
                      </template>
                    </el-tree>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never" class="px-20 mt-2">
                <el-form v-model="form">
                  <el-form-item class="mb-10" label="Type">
                    <br />
                    <el-radio-group v-model="form.type">
                      <el-radio
                        label="Tab"
                        :disabled="editIndex !== -1"
                      ></el-radio>
                      <el-radio
                        label="Feature"
                        :disabled="editIndex !== -1"
                      ></el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    v-if="form.type == 'Feature'"
                    label="Assigned To"
                  >
                    <br />
                    <el-select v-model="form.assignedTo">
                      <el-option
                        v-for="(tab, index) in customization.tab_groups"
                        :key="index"
                        :label="tab.label"
                        :value="tab.label"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="form.type == 'Feature'"
                    label="Feature Type"
                  >
                    <br />
                    <el-select
                      v-model="form.feature_type"
                      @change="getFeatures(false)"
                    >
                      <el-option
                        v-for="(feature, index) in feature_types"
                        :key="index"
                        :label="feature.key"
                        :value="feature.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="form.type == 'Feature'"
                    label="Select Feature"
                  >
                    <br />
                    <el-select
                      v-model="form.feature_id"
                      @change="autoFillLabel"
                    >
                      <el-option
                        v-for="(feature, index) in features"
                        :key="index"
                        :label="feature.label"
                        :value="feature.id"
                        :disabled="
                          form.feature_type == 'TEMPLATE'
                            ? getFeatureDisabled(feature.id)
                            : false
                        "
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== ''
                    "
                  >
                    <el-link
                      :underline="true"
                      style="color: #409eff"
                      @click="openAggregations"
                      ><i class="el-icon-menu"></i> Aggregations</el-link
                    ><br />
                  </el-form-item>
                  <el-form-item
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== ''
                    "
                    label="Permissions"
                  >
                    <br />
                    <el-checkbox v-model="form.permissions.add"
                      >Add New</el-checkbox
                    >
                    <el-checkbox v-model="form.permissions.assign"
                      >Assign & Delete</el-checkbox
                    >
                    <el-checkbox v-model="form.permissions.total"
                      >Totals</el-checkbox
                    >
                    <el-checkbox v-model="form.permissions.view"
                      >View</el-checkbox
                    >
                    <el-checkbox v-model="form.permissions.redirect"
                      >Redirect</el-checkbox
                    >
                    <el-checkbox v-model="form.permissions.bulkUpdate">
                      Bulk Update
                    </el-checkbox>
                    <el-checkbox v-model="form.permissions.rowEdit">
                      Row Edit
                    </el-checkbox>
                  </el-form-item>
                  <el-form-item
                    label="Add New Label"
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== '' &&
                      form.permissions.add
                    "
                  >
                    <el-input v-model="form.addNewLabel"></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Assign Label"
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== '' &&
                      form.permissions.assign
                    "
                  >
                    <el-input v-model="form.assignLabel"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== '' &&
                      form.permissions.assign
                    "
                    label="Assign view"
                  >
                    <el-radio-group v-model="form.permissions.assign_type">
                      <el-radio label="default_view"> Default View </el-radio>
                      <el-radio label="detailed_view"> Detailed View </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item
                    v-if="
                      form.type == 'Feature' &&
                      form.feature_type == 'RELATIONSHIP' &&
                      form.feature_id !== ''
                    "
                    label="Filters"
                  >
                    <br />
                    <el-select
                      v-model="form.filter"
                      clearable
                      filterable
                      @change="updateDOM"
                    >
                      <el-option
                        v-for="(filter, index) in entityFilters"
                        :key="index"
                        :value="filter._id"
                        :label="filter.filter_name"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="mb-10" label="Title">
                    <br />
                    <el-input
                      placeholder="Please Enter Label"
                      v-model="form.label"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <br />
                    <el-button type="primary" @click="addTab"> Add </el-button>
                    <el-button @click="clearForm">Cancel</el-button>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="actions" label="Quick Actions">
          <el-button
            class="mb-10"
            type="primary"
            size="mini"
            style="float: right"
            @click="addNewRow"
            >Add New +</el-button
          >
          <el-table :data="customization.actions" v-if="!refresh">
            <el-table-column label="Label" prop="label"> </el-table-column>
            <el-table-column label="Action type">
              <template slot-scope="scope">
                {{ actionType(scope.row.action_button_action_type) }}
              </template>
            </el-table-column>
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  circle
                  plain
                  type="warning"
                  @click="editAction(scope.$index)"
                  ><i class="el-icon-edit"
                /></el-button>
                <el-button
                  size="mini"
                  circle
                  plain
                  type="danger"
                  @click="deleteAction(scope.$index)"
                  ><i class="el-icon-delete"
                /></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Inactive/Active">
              <template slot-scope="scope">
                <el-switch
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  v-model="scope.row.active"
                  @change="updateDOM(scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column name="QAPrimary" label="Primary Action">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.QAPrimary"
                  :disabled="!scope.row.active || disableCheckbox(scope.row)"
                  @click="handleQAPrimaryChange(scope.row)"
                >
                </el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <dialog-component
      :title="'Preview'"
      :visible="previewTabDialog"
      @before-close="previewTabDialogBox"
      style="height: 100vh"
      :containerWidth="'50%'"
    >
      <div class="popUp-content">
        <el-row>
          <el-col :span="2">
            <el-button size="mini" style="margin-bottom: 5px"
              ><i class="el-icon-back"></i
            ></el-button>
          </el-col>
          <el-col :span="18">
            <img src="../../../public/assets/img/icons/Name.svg" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="customization.active_layout !== 'STANDARD' ? 5 : 0">
            <img
              src="../../../public/assets/img/icons/Profile View - 1.svg"
              v-if="customization.active_layout == 'PROFILE'"
            />
            <img
              src="../../../public/assets/img/icons/Cover View - 1.svg"
              v-if="customization.active_layout == 'COVER'"
            />
          </el-col>
          <el-col :span="customization.active_layout !== 'STANDARD' ? 19 : 24">
            <div :class="customization.active_layout == 'COVER' ? 'cover' : ''">
              <el-tabs
                v-model="previewActiveTab"
                type="card"
                @tab-click="changeSubTab"
              >
                <el-tab-pane
                  v-for="(tab, index) in previewTabs"
                  :key="index"
                  :name="tab"
                  :label="tab"
                >
                  <el-tabs
                    v-if="previewSubTabs[tab].length"
                    v-model="activeSubTab"
                  >
                    <el-tab-pane
                      v-for="(sub, index1) in previewSubTabs[tab]"
                      :key="index1"
                      :name="sub"
                      :label="sub"
                    >
                    </el-tab-pane>
                  </el-tabs>
                  <img
                    src="../../../public/assets/img/icons/Standard View - 2.svg"
                    v-if="customization.active_layout == 'STANDARD'"
                  />
                  <img
                    src="../../../public/assets/img/icons/Cover View - 2.svg"
                    v-if="customization.active_layout == 'COVER'"
                  />
                  <img
                    src="../../../public/assets/img/icons/Profile View - 2.svg"
                    v-if="customization.active_layout == 'PROFILE'"
                  />
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </div>
    </dialog-component>
    <dialog-component
      :visible="newActionDialog"
      :title="'Add New Action'"
      :containerWidth="'60%'"
      @before-close="newActionDialog = false"
    >
      <div class="mt-3 mb-3">
        <label>Label</label><br />
        <el-input
          placeholder="Enter label"
          v-model="actionForm.label"
        ></el-input
        ><br /><br />
        <el-select v-model="actionForm.action_button_action_type" class="mr-1">
          <el-option
            v-for="(action, index) in actionTypes"
            :key="index"
            :value="action.value"
            :label="action.label"
          >
          </el-option>
        </el-select>
        <template v-if="actionForm.action_button_action_type == 'NAVIGATION'">
          <el-select
            v-model="actionForm.action_button_action_navigation_type"
            class="mr-1"
          >
            <el-option
              v-for="(type, index) in navigationTypes"
              :key="index"
              :value="type.value"
              :label="type.label"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="
              actionForm.action_button_action_navigation_type ==
              'OPEN_FORM_BUILDER'
            "
            v-model="actionForm.action_button_formbuilder_id"
            class="mr-1"
          >
            <el-option> </el-option>
          </el-select>
          <el-select
            v-else-if="
              actionForm.action_button_action_navigation_type ==
              'OPEN_DOCUMENT_TEMPLATE_BUILDER'
            "
            v-model="actionForm.action_button_document_template_id"
            class="mr-1"
          >
            <el-option> </el-option>
          </el-select>
          <el-select
            v-else-if="
              actionForm.action_button_action_navigation_type ==
              'OPEN_ENTITY_VIEW'
            "
            v-model="actionForm.action_button_entity_view_id"
            class="mr-1"
          >
            <el-option> </el-option>
          </el-select>
          <template
            v-else-if="
              actionForm.action_button_action_navigation_type == 'OPEN_ENTITY'
            "
          >
            <el-select
              v-model="actionForm.action_button_entity_id"
              class="mr-1"
            >
              <el-option> </el-option>
            </el-select>
            <el-select
              v-model="actionForm.action_button_entity_filter_id"
              class="mr-1"
            >
              <el-option> </el-option>
            </el-select>
          </template>
          <template
            v-else-if="
              actionForm.action_button_action_navigation_type == 'OPEN_URL'
            "
          >
            <el-input
              v-model="actionForm.action_button_target_link"
              style="width: 300px"
            >
            </el-input>
            <span
              style="color: red"
              v-if="
                actionForm.action_button_target_link &&
                !isValidURL(actionForm.action_button_target_link)
              "
              >Invalid URL</span
            >
          </template>
          <el-select
            placeholder="Select type"
            v-model="actionForm.action_button_target_location"
          >
            <el-option
              v-for="(type, index) of locationTypes"
              :key="index"
              :label="type.label"
              :value="type.value"
            ></el-option>
          </el-select>
        </template>
        <template
          v-else-if="actionForm.action_button_action_type == 'ADD_OTHER_ENTITY'"
        >
          <el-select
            placeholder="Select an entity"
            v-model="actionForm.selected_other_entity"
            filterable
            class="mr-1"
          >
            <el-option
              v-for="entity in entities"
              :key="entity._id"
              :value="entity._id"
              :label="entity.name"
            ></el-option>
          </el-select>
          <el-button
            v-if="actionForm.selected_other_entity"
            type="text"
            @click="openMapFieldsParentToChild(actionForm)"
            >Map fields</el-button
          >
          <el-tooltip
            placement="top"
            content="You can simply add entity data of the selected entity and can map any template fields data to the selected entity"
            v-if="actionForm.selected_other_entity"
          >
            <i class="el-icon-info info-color"></i>
          </el-tooltip>
        </template>
        <template
          v-else-if="
            actionForm.action_button_action_type == 'ESTABLISH_RELATIONSHIP'
          "
        >
          <el-select v-model="actionForm.relational_entity_one">
            <el-option
              v-for="(rel, index) in relationships"
              :key="index"
              :value="rel.relationship_id"
              :label="rel.relationship_title"
            >
            </el-option>
          </el-select>
          <el-radio-group
            v-model="actionForm.action_button_relationship_type"
            v-if="actionForm.relational_entity_one"
          >
            <el-tooltip
              placement="top"
              :content="
                getTooltipContent(
                  'assign_only',
                  actionForm.relational_entity_one
                )
              "
            >
              <el-radio :label="1">Assign only</el-radio>
            </el-tooltip>
            <el-tooltip
              placement="bottom"
              :content="
                getTooltipContent('add_only', actionForm.relational_entity_one)
              "
            >
              <el-radio :label="2">Add only</el-radio>
            </el-tooltip>
            <el-tooltip
              placement="top"
              :content="
                getTooltipContent(
                  'assign_add',
                  actionForm.relational_entity_one
                )
              "
            >
              <el-radio :label="3">Assign & Add</el-radio>
            </el-tooltip>
            <el-tooltip
              placement="bottom"
              :content="
                getTooltipContent('view_data', actionForm.relational_entity_one)
              "
            >
              <el-radio :label="4">View data</el-radio>
            </el-tooltip>
            <el-tooltip
              placement="bottom"
              :content="
                getTooltipContent('view_add', actionForm.relational_entity_one)
              "
            >
              <el-radio :label="5">View & Add</el-radio>
            </el-tooltip>
          </el-radio-group>
          <div
            v-if="
              actionForm.action_button_relationship_type == 3 ||
              actionForm.action_button_relationship_type == 2 ||
              actionForm.action_button_relationship_type == 5
            "
          >
            <el-button
              type="text"
              @click="openMapFieldsParentToChild(actionForm)"
              class="ml-1"
              >Map fields</el-button
            >
            <el-tooltip
              placement="top"
              content="You can simply add entity data of the selected entity and can map any template fields data to the selected entity"
            >
              <i class="el-icon-info info-color"></i>
            </el-tooltip>
          </div>
        </template>
      </div>
      <span slot="footer">
        <el-button size="mini" @click="newActionDialog = false"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :disabled="addActionDisabled()"
          @click="addNewAction"
          >Add</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :visible="aggregationsVisible"
      :containerWidth="'95%'"
      :containerHeight="'80%'"
      :title="'Aggregations'"
      @before-close="aggregationsVisible = false"
    >
      <el-row class="mt-2 mb-2" v-loading="aggregationLoading">
        <el-col :span="10">
          <el-table :data="form.aggregations">
            <el-table-column label="Label" prop="label"> </el-table-column>
            <el-table-column label="Field type" prop="type"> </el-table-column>
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  circle
                  plain
                  type="warning"
                  @click="editAgg(scope.$index)"
                  ><i class="el-icon-edit"
                /></el-button>
                <el-button
                  size="mini"
                  circle
                  plain
                  type="danger"
                  @click="deleteAgg(scope.$index)"
                  ><i class="el-icon-delete"
                /></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="14">
          <el-card shadow="never" class="px-20">
            <el-form v-model="newAggregationObject">
              <el-form-item label="Label"
                ><br />
                <el-input
                  placeholder="Enter label"
                  v-model="newAggregationObject.label"
                ></el-input>
              </el-form-item>
              <el-form-item label="Type"
                ><br />
                <el-radio-group v-model="newAggregationObject.type">
                  <el-radio label="Display"></el-radio>
                  <el-radio label="Formula"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="Select Field"
                v-if="newAggregationObject.type == 'Display'"
                ><br />
                <el-select
                  v-model="newAggregationObject.display_field"
                  filterable
                >
                  <el-option
                    v-for="(
                      aggregation, index
                    ) in relationshipEntityAggregationFields"
                    :key="index"
                    :value="aggregation.agg_key"
                    :label="aggregation.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Select Filter"
                v-if="newAggregationObject.type == 'Display'"
                ><br />
                <el-select v-model="newAggregationObject.filter_id" filterable>
                  <el-option
                    v-for="(filter, index) in entityFilters"
                    :key="index"
                    :value="filter._id"
                    :label="filter.filter_name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else>
                <div class="formula-dialog" ref="form">
                  <div class="artboard" v-if="!refreshFields">
                    <div
                      v-for="(
                        item, index
                      ) of newAggregationObject.selected_fields"
                      :key="index"
                    >
                      <el-button
                        v-if="showFieldButton(item.type)"
                        size="medium"
                        class="btn-variable"
                        @click="swapFields(index)"
                        :class="getActiveClass(index)"
                      >
                        <span v-if="item.type == 'OPERATOR'">{{
                          item.value
                        }}</span>

                        <span v-if="item.type == 'FIELD'">{{
                          item.label
                        }}</span>
                        <span v-if="item.type == 'PARENTHESES'">{{
                          item.value
                        }}</span>
                        <span v-if="item.type == 'FORMULA'">{{
                          item.formula
                        }}</span>
                        <span v-if="item.type == 'NUMBER'">{{
                          item.value
                        }}</span>
                        <span v-if="item.type == 'CURRENCY'">{{
                          item.currency
                        }}</span>
                        <span v-if="item.type == 'DATE'">{{ item.date }}</span>
                        <span v-if="item.type == 'TIME'">{{ item.time }}</span>
                        <span v-if="item.type == 'DATE_TIME_RANGE'">{{
                          item.date_time_range
                        }}</span>
                        <span v-if="item.type == 'DATE_RANGE'">{{
                          item.date_range
                        }}</span>
                        <span v-if="item.type == 'LIST'">{{ item.list }}</span>
                        <span v-if="item.type == 'GLOBAL_VARIABLE'">{{
                          item.field_name
                        }}</span>
                        <span>
                          <img
                            src="@/assets/img/icons/transfer.svg"
                            alt="innerchange"
                          />
                        </span>
                      </el-button>
                      <el-button
                        v-if="item.type == 'OPERATOR'"
                        :type="getOperationType(item.value)"
                        size="mini"
                        class="btn-operation"
                        @click="swapFields(index)"
                        circle
                        :class="getActiveClass(index)"
                      >
                        <img
                          v-if="item.value == '+'"
                          src="@/assets/img/calculator/plus.svg"
                          alt="brackets-left"
                        />
                        <img
                          v-if="item.value == '-'"
                          src="@/assets/img/calculator/minus.svg"
                          alt="brackets-left"
                        />
                        <img
                          v-if="item.value == '*'"
                          src="@/assets/img/calculator/close.svg"
                          alt="brackets-left"
                        />
                        <img
                          v-if="item.value == '/'"
                          src="@/assets/img/calculator/divider.svg"
                          alt="brackets-left"
                        />
                      </el-button>
                      <el-button
                        v-if="item.type == 'PARENTHESES' && item.value == '('"
                        type="text"
                        effect="dark"
                        class="text-button"
                        >(</el-button
                      >
                      <el-button
                        v-if="item.type == 'PARENTHESES' && item.value == ')'"
                        type="text"
                        effect="dark"
                        class="text-button"
                        >)</el-button
                      >
                    </div>
                  </div>
                  <div class="variables-fields mt-1">
                    <div class="mr-1">
                      <label>Filter</label><br />
                      <el-select v-model="filterField" filterable>
                        <el-option value="" label="None"></el-option>
                        <el-option
                          v-for="(filter, index) in entityFilters"
                          :key="index"
                          :value="filter._id"
                          :label="filter.filter_name"
                        >
                        </el-option> </el-select
                      ><br />
                      <label>Numbers</label>
                      <el-input
                        v-model="manualNumber"
                        placeholder="Enter Number"
                        :disabled="checkAggregationDisabled('number')"
                      >
                        <el-button
                          slot="append"
                          type="primary"
                          icon="el-icon-plus"
                          @click="addNumber"
                          :disabled="manualNumber < 0"
                        ></el-button>
                      </el-input>
                    </div>
                    <div>
                      <label>Fields</label><br />
                      <el-select
                        v-model="showSelectField"
                        :disabled="checkAggregationDisabled('field')"
                        @change="onSelectAggregation"
                        placeholder="Select Fields"
                        filterable
                      >
                        <el-option
                          v-for="(
                            item, i
                          ) of relationshipEntityAggregationFields"
                          :key="item.key + '_' + i"
                          :label="item.label"
                          :value="item.agg_key"
                        ></el-option> </el-select
                      ><br />
                      <label>Result type</label><br />
                      <el-select v-model="newAggregationObject.result_type">
                        <el-option
                          label="PERCENTAGE"
                          value="PERCENTAGE"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="operaters">
                      <el-button-group class="brackets-btn-group">
                        <el-button
                          type="success"
                          @click="onSelectOperator('+')"
                          class="btn-operation"
                          size="mini"
                          :disabled="checkAggregationDisabled('operator')"
                        >
                          <img
                            src="@/assets/img/calculator/plus.svg"
                            alt="brackets-left"
                          />
                        </el-button>
                        <el-button
                          type="primary"
                          @click="onSelectOperator('-')"
                          class="btn-operation"
                          size="mini"
                          :disabled="checkAggregationDisabled('operator')"
                        >
                          <img
                            src="@/assets/img/calculator/minus.svg"
                            alt="brackets-left"
                          />
                        </el-button>
                        <el-button
                          type="info"
                          @click="onSelectOperator('*')"
                          class="btn-operation"
                          size="mini"
                          :disabled="checkAggregationDisabled('operator')"
                        >
                          <img
                            src="@/assets/img/calculator/close.svg"
                            alt="brackets-left"
                          />
                        </el-button>
                        <el-button
                          type="warning"
                          @click="onSelectOperator('/')"
                          class="btn-operation"
                          size="mini"
                          :disabled="checkAggregationDisabled('operator')"
                        >
                          <img
                            src="@/assets/img/calculator/divider.svg"
                            alt="brackets-left"
                          />
                        </el-button>
                      </el-button-group>
                      <el-button-group class="brackets-btn-group">
                        <el-button
                          type="info"
                          @click="onSelectParentheses('(')"
                          class="btn-operation"
                          :disabled="
                            checkAggregationDisabled('parentheses', '(')
                          "
                          >(</el-button
                        >
                        <el-button
                          type="info"
                          @click="onSelectParentheses(')')"
                          class="btn-operation"
                          :disabled="
                            checkAggregationDisabled('parentheses', ')')
                          "
                          >)</el-button
                        >

                        <el-button
                          type="danger"
                          @click="popAggregationField()"
                          class="btn-operation"
                          :disabled="
                            newAggregationObject &&
                            newAggregationObject.selected_fields &&
                            newAggregationObject.selected_fields.length < 1
                          "
                        >
                          <i class="el-icon-delete"></i>
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <br />
                <div style="float: right">
                  <el-button @click="resetAgg">Cancel</el-button>
                  <el-button
                    type="primary"
                    @click="addAggregation"
                    :disabled="disableAggregationAdd"
                    >Add</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </dialog-component>
    <dialog-component
      title="Map fields"
      :visible="fieldsMappingModal"
      @before-close="resetFieldMappingModal"
      :containerMaxHeight="'90%'"
      :containerWidth="'80%'"
    >
      <div class="vue-data-table-default mt-1" v-loading="loading">
        <div
          :style="{
            'max-height': '65vh',
            'overflow-y': 'auto',
            'scrollbar-width': 'thin',
          }"
        >
          <el-table
            :data="relatedEntityFields"
            style="width: 100%"
            :loading="loading"
            class="map-table"
          >
            <el-table-column
              :label="relatedEntityName + ' Fields'"
              prop="label"
            >
              <template slot-scope="scope">{{
                scope.row.template_name + " - " + scope.row.label
              }}</template>
            </el-table-column>
            <el-table-column width="100">
              <div style="font-size: 16px">
                <i class="el-icon-right"></i>
              </div>
            </el-table-column>
            <el-table-column label="Template" prop="label">
              <template slot-scope="scope">
                <el-select
                  v-model="selectedMappedTemplate[scope.row.key]"
                  placeholder="Select mapping field"
                  size="mini"
                  clearable
                  class="slect-style"
                >
                  <el-option
                    v-for="(temp, index) of currentEntity.templates"
                    :key="index"
                    :label="temp.templateInfo.name"
                    :value="temp.template_id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Fields" prop="label">
              <template slot-scope="scope">
                <el-select
                  v-if="selectedMappedTemplate[scope.row.key]"
                  v-model="selectedMappedField[scope.row.key]"
                  placeholder="Select mapping field"
                  size="mini"
                  clearable
                  class="slect-style"
                >
                  <el-option
                    v-for="field of getAnyTemplateFields(
                      scope.row,
                      selectedMappedTemplate[scope.row.key]
                    )"
                    :key="field.key"
                    :label="field.label"
                    :value="field.template_id + '#' + field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFieldMappingModal">Cancel</el-button>
        <el-button type="primary" @click="saveMapping">Save changes</el-button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import { makeSlug } from "../../helpers/appHelper";
// import * as _ from "lodash";
import { uniqBy } from "../../helpers/lodashHelper";
import EntitiesHelper from "../../mixins/EntitiesHelper";
import {
  updateEntityCustomizationData,
  addSingleCustomizationToLocal,
} from "@/repo/entityCustomizationRepo";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  name: "EntityViewConfiguration",
  mixins: [EntitiesHelper],
  props: [
    "currentEntity",
    "customization",
    "relationships",
    "nestedRelationships",
    "entityViews",
    "entities",
    "formBuilders",
    "docTemplates",
  ],
  async mounted() {
    // this.fetchingDataLoading = true;
    // if (
    //   this.currentEntity &&
    //   this.customization == undefined
    // ) {
    //   this.configureClicked = false;
    // } else {
    //   this.checkActionsConfigured();
    //   await this.fetchRelationshipsAndNestedRelationships();
    //   this.configureClicked = true;
    //   this.alreadyConfigured = true;
    // }
    // console.log("relationships", this.relationships);
    // this.fetchingDataLoading = false;
    //Adding newly added buttons to already saved customizations
    if (this.customization?.buttons) {
      this.newlyAddedButtons.forEach((bt) => {
        let button = (this.customization?.buttons || []).find(
          (e) => e.value == bt.value
        );
        if (!button) {
          this.customization.buttons.push(bt);
        }
      });
    }
  },
  computed: {
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityNestedRelationships",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("entityCustomization", [
      "getNewlyCreatedCustomization",
      "getUpdatedCustomizationStatus",
    ]),
    disableAggregationAdd() {
      return this.newAggregationObject.label.trim() == "" ||
        (this.newAggregationObject.display_field == "" &&
          !this.newAggregationObject.selected_fields.length)
        ? true
        : false;
    },
  },
  data() {
    return {
      templates: [],
      previousTabsConfig:null,
      configureClicked: false,
      activeTab: "Buttons",
      fetchingDataLoading: false,
      tableLoading: false,
      previewTabDialog: false,
      previewTabs: [],
      previewSubTabs: {},
      previewActiveTab: "",
      alreadyConfigured: false,
      otherPermissions: ["Emails", "Files", "eSignatures"],
      feature_types: [
        {
          key: "Template",
          value: "TEMPLATE",
        },
        {
          key: "Relationship",
          value: "RELATIONSHIP",
        },
        {
          key: "Nested Relationship",
          value: "NESTED_RELATIONSHIP",
        },
        {
          key: "Others",
          value: "OTHERS",
        },
      ],
      features: [],
      form: {
        label: "",
        type: "Tab",
        assignedTo: "",
        feature_type: "",
        feature_id: "",
        permissions: {
          add: true,
          assign: true,
          total: true,
          view: true,
          redirect: true,
          bulkUpdate: true,
          rowEdit: true,
          assign_type: "default_view",
        },
        filter: "",
        addNewLabel: "Add New",
        assignLabel: "Assign",
        aggregations: [],
      },
      editIndex: -1,
      props: {
        label: "label",
        children: "tabs",
      },
      disabledFeaturesList: [],
      refresh: false,
      parentIndex: -1,
      contactDetailsId: "",
      getClass: "",
      entityFilters: [],
      newActionDialog: false,
      actionForm: {
        label: "",
        active: true,
      },
      actionEditIndex: -1,
      newAggregationObject: {
        label: "",
        type: "Display",
        display_field: "",
        selected_fields: [],
        result_type: "",
        filter_id: "",
      },
      aggregationsVisible: false,
      activeClassIndex: -1,
      refreshFields: false,
      showSelectField: "",
      showSelectOperator: "",
      showSelectParentheses: "",
      manualNumber: "",
      relationshipEntityAggregationFields: [],
      aggregationLoading: true,
      editAggIndex: -1,
      filterField: "",
      maxPrimaryButtons: 3,
      maxPrimaryActions: 3,
      newlyAddedButtons: [
        {
          buttonName: "Attachments",
          value: "Attachments",
          visibility: true,
          isPrimary: false,
        },
      ],
      actionTypes: [
        {
          value: "NAVIGATION",
          label: "Navigation",
        },
        {
          value: "ESTABLISH_RELATIONSHIP",
          label: "Relationships",
        },
        {
          value: "ADD_OTHER_ENTITY",
          label: "Add Entity Data",
        },
      ],
      navigationTypes: [
        {
          value: "OPEN_URL",
          label: "Open URL",
        },
        {
          value: "OPEN_FORM_BUILDER",
          label: "Open Form builder",
        },
        {
          value: "OPEN_DOCUMENT_TEMPLATE_BUILDER",
          label: "Open document template",
        },
        {
          value: "OPEN_ENTITY",
          label: "Open Entity",
        },
        {
          value: "OPEN_ENTITY_VIEW",
          label: "Open Entity View",
        },
      ],
      locationTypes: [
        {
          value: "SELF",
          label: "Same Page",
        },
        {
          value: "NEW_TAB",
          label: "New Tab",
        },
        {
          value: "NEW_WINDOW",
          label: "New Window",
        },
      ],
      fieldsMappingModal: false,
      selectedMappedField: {},
      relatedEntityFields: [],
      relatedEntityName: "",
      selectedMappedTemplate: {},
      loading: false,
    };
  },
  created() {
    if (this.customization && this.customization.tab_groups) {
      this.previousTabsConfig = JSON.parse(
        JSON.stringify(this.customization.tab_groups)
      );
    }
    this.checkTabsConfigStatus();
  },
  methods: {
    actionType(action) {
      return this.actionTypes.find((a) => a.value == action).label;
    },
    getTooltipContent(type, entityId) {
      let label = "";
      let entity = this.entities.find((e) => e._id == entityId);
      let entityName = entity?.name || "Selected entity";
      switch (type) {
        case "assign_only":
          label = "Can only assign existed " + entityName + " to self entity";
          break;
        case "add_only":
          label =
            "Can only add new " +
            entityName +
            " and that " +
            entityName +
            " will be assign to self entity";
          break;
        case "assign_add":
          label =
            "Can add new " +
            entityName +
            " or assign existed " +
            entityName +
            " to self entity";
          break;
        case "view_data":
          label = "Can view " + entityName + " related to self entity";
          break;
        case "view_add":
          label =
            "Can view existed " +
            entityName +
            " or add new " +
            entityName +
            " related to self entity";
          break;
      }
      return label;
    },
    resetFieldMappingModal() {
      this.selectedMappedField = {};
      this.fieldsMappingModal = false;
    },
    saveMapping() {
      this.$set(
        this.actionForm,
        "new_entity_data_mapping",
        this.selectedMappedField
      );
      this.resetFieldMappingModal();
    },
    getAnyTemplateFields(rowField, selectedTemplate) {
      let numberFields = [
        "NUMBER",
        "CURRENCY",
        "AGGREGATE_FUNCTION",
        "FORMULA",
      ];
      let selectedTemplateData = this.currentEntity.templates.find(
        (e) => e.template_id == selectedTemplate
      );
      let fields = [];
      if (selectedTemplateData?.templateInfo?.sections?.[0]?.fields) {
        fields = selectedTemplateData.templateInfo.sections[0].fields;
      }
      return fields.filter((field) => {
        if (
          field.inputType == rowField.inputType ||
          (numberFields.includes(rowField.inputType) &&
            numberFields.includes(field.inputType)) ||
          (rowField.inputType == "FORMULA" &&
            field.inputType == rowField.result_type) ||
          (rowField.inputType == "SINGLE_LINE_TEXT" &&
            field.inputType == "AUTO_INCREMENT_NUMBER")
        ) {
          field.template_id = selectedTemplate;
          return true;
        }
      });
    },
    openMapFieldsParentToChild(action) {
      this.fieldsMappingModal = true;
      this.selectedMappedField = JSON.parse(
        JSON.stringify(action.new_entity_data_mapping || {})
      );
      this.getSelectedEntityFields(action);
    },
    async getSelectedEntityFields(action) {
      this.loading = true;
      this.relatedEntityFields = [];
      let id = action.relational_entity_one || action.selected_other_entity;
      const entity = await postAPICall("GET", `/entities/${id}`);
      this.relatedEntityName = entity?.data?.name ? entity.data.name : "Entity";
      if (entity?.data?.templates) {
        entity.data.templates.forEach((template) => {
          if (template?.templateInfo?.sections[0]?.fields) {
            this.relatedEntityFields = [
              ...this.relatedEntityFields,
              ...template.templateInfo.sections[0].fields.map((f) => {
                f.template_id = template.template_id;
                f.template_name = template.templateInfo.name;
                if (f.key && !f.key.includes("#")) {
                  f.key = template.template_id + "#" + f.key;
                }
                return f;
              }),
            ];
          }
        });
      }
      this.loading = false;
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    addAggregation() {
      if (this.editAggIndex > -1) {
        this.form.aggregations[this.editAggIndex] = {
          ...this.newAggregationObject,
        };
      } else {
        this.form.aggregations.push(this.newAggregationObject);
      }
      this.resetAgg();
    },
    resetAgg() {
      this.newAggregationObject = {
        label: "",
        type: "Display",
        display_field: "",
        selected_fields: [],
        result_type: "",
      };
      this.editAggIndex = -1;
    },
    editAgg(index) {
      this.editAggIndex = index;
      this.newAggregationObject = { ...this.form.aggregations[index] };
    },
    deleteAgg(index) {
      this.$confirm("Are you sure to delete this aggregation", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.form.aggregations.splice(index, 1);
      });
    },
    async openAggregations() {
      this.aggregationsVisible = true;
      this.aggregationLoading = true;
      let allowed = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
      this.relationshipEntityAggregationFields = [];
      let relationship = this.relationships.find(
        (rel) => rel._id == this.form.feature_id
      );
      let allFields = await this.fetchEntityDetails(
        relationship.relationship_id,
        false,
        false,
        false,
        true
      );
      allFields.map((fd) => {
        if (
          allowed.includes(fd.inputType) ||
          (fd.inputType == "FORMULA" && allowed.includes(fd.result_type))
        ) {
          this.relationshipEntityAggregationFields = [
            ...this.relationshipEntityAggregationFields,
            ...[
              {
                ...fd,
                ...{
                  agg_key: "SUM@" + fd.template_key,
                  label: "Sum - " + fd.label,
                },
              },
              {
                ...fd,
                ...{
                  agg_key: "AVG@" + fd.template_key,
                  label: "Average - " + fd.label,
                },
              },
            ],
          ];
        }
      });
      this.aggregationLoading = false;
    },
    handlePrimaryChange(row) {
      const totalSelectedCount = this.getTotalSelectedCount();
      if (totalSelectedCount > this.maxPrimaryButtons) {
        row.isPrimary = false;
        this.$message.error(
          "You can only select up to 3 primary checkboxes in total."
        );
      }
    },
    handleQAPrimaryChange(row) {
      const totalSelectedCount = this.getTotalSelectedCount();
      if (totalSelectedCount > this.maxPrimaryButtons) {
        row.QAPrimary = false;
        this.$message.error(
          "You can only select up to 3 primary checkboxes in total."
        );
      }
    },
    getTotalSelectedCount() {
      const primaryButtonsCount = this.customization.buttons.filter(
        (button) => button.isPrimary
      ).length;
      const primaryActionsCount = this.customization.actions.filter(
        (action) => action.QAPrimary
      ).length;
      return primaryButtonsCount + primaryActionsCount;
    },
    isPrimaryDisabled(row) {
      const totalSelectedCount = this.getTotalSelectedCount();
      return (
        !row.visibility ||
        [
          "Update & Next",
          "Save",
          "Save & Next",
          "Save & New",
          "Duplicate",
        ].includes(row.buttonName) ||
        (totalSelectedCount >= this.maxPrimaryButtons && !row.isPrimary)
      );
    },
    disableCheckbox(row) {
      const totalSelectedCount = this.getTotalSelectedCount();
      return totalSelectedCount >= this.maxPrimaryButtons && !row.QAPrimary;
    },
    showFieldButton(type) {
      if (
        type == "FIELD" ||
        type == "NUMBER" ||
        type == "TIME" ||
        type == "DATE_TIME_RANGE" ||
        type == "DATE_RANGE" ||
        type == "LIST" ||
        type == "DATE" ||
        type == "CURRENCY" ||
        type == "GLOBAL_VARIABLE" ||
        type == "FORMULA"
      ) {
        return true;
      } else {
        return false;
      }
    },
    swapFields(index) {
      if (this.activeClassIndex > -1) {
        this.activeClassIndex = -1;
      } else {
        this.activeClassIndex = index;
      }
      this.refreshFormulaFields();
    },
    refreshFormulaFields() {
      this.refreshFields = true;
      setTimeout(() => {
        this.refreshFields = false;
      });
    },
    getActiveClass(index) {
      if (this.activeClassIndex == index) {
        return "active";
      }
    },
    getOperationType(operator) {
      if (operator == "+") {
        return "success";
      } else if (operator == "-") {
        return "primary";
      } else if (operator == "*") {
        return "info";
      } else if (operator == "/") {
        return "warning";
      }
    },
    checkAggregationDisabled(type, item = "") {
      if (
        type == "field" ||
        type == "number" ||
        type == "currency" ||
        type == "global_variable" ||
        type == "date" ||
        type == "time" ||
        type == "list"
      ) {
        if (
          this.newAggregationObject?.selected_fields &&
          this.newAggregationObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newAggregationObject.selected_fields[this.activeClassIndex];
            if (
              fieldData &&
              (fieldData.type == "FIELD" ||
                fieldData.type == "NUMBER" ||
                fieldData.type == "DATE" ||
                fieldData.type == "TIME" ||
                fieldData.type == "LIST" ||
                fieldData.type == "CURRENCY" ||
                fieldData.type == "GLOBAL_VARIABLE" ||
                fieldData.type == "FORMULA")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newAggregationObject?.selected_fields &&
              (this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "FIELD" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "NUMBER" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "TIME" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE_TIME_RANGE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "DATE_RANGE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "LIST" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "GLOBAL_VARIABLE" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "FORMULA" ||
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "CURRENCY" ||
                (this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].type == "PARENTHESES" &&
                  this.newAggregationObject.selected_fields[
                    this.newAggregationObject.selected_fields.length - 1
                  ].value == ")"))
            );
          }
        }
        return false;
      } else if (type == "operator") {
        if (
          this.newAggregationObject?.selected_fields &&
          this.newAggregationObject.selected_fields.length
        ) {
          if (this.activeClassIndex > -1) {
            let fieldData =
              this.newAggregationObject.selected_fields[this.activeClassIndex];
            if (fieldData && fieldData.type == "OPERATOR") {
              return false;
            } else {
              return true;
            }
          } else {
            return (
              this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "OPERATOR" ||
              (this.newAggregationObject.selected_fields[
                this.newAggregationObject.selected_fields.length - 1
              ].type == "PARENTHESES" &&
                this.newAggregationObject.selected_fields[
                  this.newAggregationObject.selected_fields.length - 1
                ].value == "(")
            );
          }
        }
        return true;
      } else if (type == "parentheses") {
        if (this.activeClassIndex > -1) {
          return true;
        } else {
          let openParenthesesCount = 0;
          let lastOpenIndex = -1;
          let closeParenthesesCount = 0;
          if (
            this.newAggregationObject &&
            this.newAggregationObject.selected_fields
          ) {
            let selectedFieldsLength =
              this.newAggregationObject &&
              this.newAggregationObject.selected_fields
                ? this.newAggregationObject.selected_fields.length
                : 0;
            let index = -1;
            this.newAggregationObject.selected_fields.map((el) => {
              index += 1;
              if (el.type == "PARENTHESES") {
                if (el.value == "(") {
                  openParenthesesCount += 1;
                  lastOpenIndex = index;
                } else {
                  closeParenthesesCount += 1;
                }
                return;
              }
            });
            let afterLastOpenLength =
              selectedFieldsLength - (lastOpenIndex + 1);
            if (!selectedFieldsLength) {
              return item == ")";
            } else if (selectedFieldsLength < 3) {
              //condition for formula
              return (
                this.newAggregationObject.selected_fields[
                  selectedFieldsLength - 1
                ].type != "OPERATOR" || item == ")"
              );
            } else {
              if (item == "(") {
                if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "OPERATOR"
                ) {
                  return false;
                } else if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "PARENTHESES" &&
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].value == "("
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                if (
                  this.newAggregationObject.selected_fields[
                    selectedFieldsLength - 1
                  ].type == "OPERATOR"
                ) {
                  return true;
                } else if (openParenthesesCount <= closeParenthesesCount) {
                  return true;
                } else if (afterLastOpenLength < 2) {
                  return true;
                } else if (
                  afterLastOpenLength > 2 &&
                  afterLastOpenLength % 2 == 0
                ) {
                  if (
                    this.newAggregationObject.selected_fields[
                      selectedFieldsLength - 1
                    ].type == "PARENTHESES" &&
                    this.newAggregationObject.selected_fields[
                      selectedFieldsLength - 1
                    ].value == ")"
                  ) {
                    return false;
                  }
                  return true;
                } else {
                  return false;
                }
              }
            }
          }
        }
      } else {
        return false;
      }
    },
    addNumber() {
      if (this.activeClassIndex > -1) {
        let fieldData =
          this.newAggregationObject.selected_fields[this.activeClassIndex];
        if (
          fieldData.type == "FIELD" ||
          fieldData.type == "FORMULA" ||
          fieldData.type == "NUMBER" ||
          fieldData.type == "DATE" ||
          fieldData.type == "CURRENCY" ||
          fieldData.type == "TIME" ||
          fieldData.type == "LIST" ||
          fieldData.type == "GLOBAL_VARIABLE"
        ) {
          let number = this.manualNumber;
          this.newAggregationObject.selected_fields[this.activeClassIndex] = {
            type: fieldData.type == "DATE" ? "DATE" : "NUMBER",
            number,
          };
          this.manualNumber = "";
          this.activeClassIndex = -1;
        }
      } else {
        if (this.manualNumber > 0) {
          let number = this.manualNumber;
          this.newAggregationObject.selected_fields.push({
            type: "NUMBER",
            value: number,
          });
          this.manualNumber = "";
        }
      }
    },
    onSelectAggregation(item) {
      let itemField = this.relationshipEntityAggregationFields.find(
        (e) => e.agg_key == item
      );
      let obj = {
        type: "FIELD",
        agg_type: this.showSelectField.split("@")[0] == "SUM" ? "SUM" : "AVG",
        key: itemField.agg_key.split("@")[1],
        template_id: itemField.template_id,
        label: itemField.label,
        result_type: itemField.result_type ? itemField.result_type : "",
        filter_id: this.filterField,
      };
      console.log("obj", obj);
      if (
        this.newAggregationObject?.selected_fields &&
        this.newAggregationObject.selected_fields.length
      ) {
        this.newAggregationObject.selected_fields.push(obj);
      } else {
        this.newAggregationObject.selected_fields = [obj];
      }
      this.showSelectField = "";
      this.filterField = "";
      this.refreshFormulaFields();
    },
    onSelectOperator(operator) {
      if (
        this.activeClassIndex > -1 &&
        this.newAggregationObject.selected_fields[this.activeClassIndex].type ==
          "OPERATOR"
      ) {
        this.newAggregationObject.selected_fields[this.activeClassIndex] = {
          type: "OPERATOR",
          value: operator,
        };
        this.showSelectOperator = "";
        this.activeClassIndex = -1;
      } else {
        this.newAggregationObject.selected_fields.push({
          type: "OPERATOR",
          value: operator,
        });
        this.showSelectOperator = "";
      }
    },
    onSelectParentheses(parentheses) {
      this.newAggregationObject.selected_fields.push({
        type: "PARENTHESES",
        value: parentheses,
      });
    },
    popAggregationField() {
      this.newAggregationObject.selected_fields.pop();
      this.refreshFormulaFields();
    },
    async saveCustomization() {
      if (this.customization.label.trim() == "") {
        this.$notify({
          title: "Error",
          message: "Please enter label",
          type: "error",
        });
        return;
      }

      if (!this.previousTabsConfig) {
        this.previousTabsConfig = JSON.parse(
          JSON.stringify(this.customization.tab_groups)
        );
      }

      // let isChanged = false;

      if (this.customization.tab_groups && this.previousTabsConfig) {
        // isChanged =
          JSON.stringify(this.customization.tab_groups) !==
          JSON.stringify(this.previousTabsConfig);
      }
      if (this.customization._id) {
        await this.$store.dispatch(
          "entityCustomization/updateCustomization",
          this.customization
        );

        if (
          this.getUpdatedCustomizationStatus &&
          this.getUpdatedCustomizationStatus.success
        ) {
          if (this.getUpdatedCustomizationStatus?.data) {
            await updateEntityCustomizationData(
              this.customization._id,
              this.getUpdatedCustomizationStatus.data
            );
          }
          this.$notify({
            title: "Success",
            message: "Customization updated successfully",
            type: "success",
          });
          this.$emit("goBack", true);
          bus.$emit("tabsConfigStatus", false);
        } else {
          this.$notify({
            title: "Error",
            message: this.getUpdatedCustomizationStatus.message,
            type: "error",
          });
        }
      } else {
        let params = {
          ...{ entity_id: this.currentEntity._id },
          ...this.customization,
        };
        await this.$store.dispatch(
          "entityCustomization/createNewCustomization",
          params
        );

        if (
          this.getNewlyCreatedCustomization &&
          this.getNewlyCreatedCustomization.success
        ) {
          if (this.getNewlyCreatedCustomization.data) {
            await addSingleCustomizationToLocal(
              this.getNewlyCreatedCustomization.data
            );
          }
          this.$notify({
            title: "Success",
            message: "Customization created successfully",
            type: "success",
          });
          this.$emit("goBack", true);
          bus.$emit("tabsConfigStatus", false);
        } else {
          this.$notify({
            title: "Error",
            message: this.getNewlyCreatedCustomization.message,
            type: "error",
          });
        }
      }
    },
    checkTabsConfigStatus() {
      const isChanged =
        JSON.stringify(this.customization.tab_groups) !==
        JSON.stringify(this.previousTabsConfig);
      bus.$emit("tabsConfigStatus", isChanged);
    },
    goBackToCustomization() {
      this.$emit("goBack");
    },
    addNewRow() {
      this.newActionDialog = true;
    },
    resetActionForm() {
      this.actionForm = {
        label: "",
        active: true,
      };
      this.actionEditIndex = -1;
      this.newActionDialog = false;
    },
    addNewAction() {
      this.refresh = true;
      let newAction;
      if (this.actionEditIndex == -1) {
        newAction = { ...this.actionForm };
        this.customization.actions.push(newAction);
      } else {
        newAction = {
          ...this.actionForm,
        };
        this.customization.actions[this.actionEditIndex] = newAction;
      }
      this.updateDOM(newAction);
      this.resetActionForm();
    },
    addActionDisabled() {
      return this.actionForm.label.trim() == "";
    },
    getRelationshipLabel(rel_id) {
      return this.relationships.find((e) => e.relationship_id == rel_id)
        .relationship_title;
    },
    editAction(index) {
      this.actionEditIndex = index;
      this.actionForm = {
        ...this.customization.actions[index],
      };
      this.newActionDialog = true;
    },
    deleteAction(index) {
      this.$confirm("Do you want to delete this action ?", "Are you sure ?", {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.customization.actions.splice(index, 1);
      });
    },
    previewTabDialogBox() {
      this.previewTabDialog = false;
    },
    async configureEntityView() {
      this.fetchingDataLoading = true;
      await this.fetchRelationshipsAndNestedRelationships();
      this.currentEntity["entity_view_configuration"] = {
        buttons: [
          {
            buttonName: "Save",
            value: "Save",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Save & Next",
            value: "Save & Next",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Save & New",
            value: "Save & New",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Update & Next",
            value: "Update & Next",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Duplicate",
            value: "Duplicate",
            visibility: false,
            isPrimary: false,
          },
          {
            buttonName: "View",
            value: "View",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Edit",
            value: "Edit",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Delete",
            value: "Delete",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Send Notification",
            value: "Send Notification",
            visibility: true,
            isPrimary: false,
          },
          {
            buttonName: "Attachments",
            value: "Attachments",
            visibility: true,
            isPrimary: false,
          },
        ],
        tab_groups: [],
        actions: [],
      };
      switch (this.currentEntity.entity_type) {
        case "INDIVIDUAL":
          this.customization["active_layout"] = "PROFILE";
          break;
        case "BUSINESS":
          this.customization["active_layout"] = "COVER";
          break;
        case "GENERAL":
          this.customization["active_layout"] = "STANDARD";
          break;
      }
      this.fetchingDataLoading = false;
      this.configureClicked = true;
    },
    goBack() {
      this.activeTab = "Buttons";
      this.configureClicked = false;
    },
    resetButtons() {
      this.$confirm(
        "Do you want the button labels to be reset to default ?",
        "Warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.tableLoading = true;
        this.customization.buttons.map((e) => {
          e.value = e.buttonName;
          e.visibility = true;
        });
        this.tableLoading = false;
      });
    },
    previewConfiguration() {
      if (!this.customization.tab_groups.length) {
        this.$message.warning("Please add some tabs to preview");
        return;
      }
      this.previewTabs = [];
      this.customization.tab_groups.map((e) => {
        this.previewTabs.push(e.label);
        if (e.tabs.length > 1) {
          this.previewSubTabs[e.label] = e.tabs.map((f) => f.label);
        } else {
          this.previewSubTabs[e.label] = [];
        }
      });
      this.previewActiveTab = this.previewTabs[0];
      this.activeSubTab =
        this.previewSubTabs[this.previewActiveTab].length > 1
          ? this.previewSubTabs[this.previewActiveTab][0]
          : "";
      this.previewTabDialog = true;
    },
    async fetchRelationshipsAndNestedRelationships() {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: this.currentEntity._id }
      );
      this.relationships = [...(this.getEntityRelationships || [])];
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        {
          entity_id: this.currentEntity._id,
          fetchAll: true,
        }
      );
      this.nestedRelationships = [...(this.getEntityNestedRelationships || [])];
      this.relationships = this.relationships.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }

        return e;
      });
      this.relationships = this.relationships.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.relationships = this.relationships.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      this.nestedRelationships = this.nestedRelationships.map((e) => {
        // e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.relationship_title;
        // e.relationship_slug =
        //     e.parent_relationship_details.relationship_slug +
        //     "-" +
        //     makeSlug(e.relationship_title);
        // e.relationship_id = e.owner_type == 'PARENT' ? e.child_entity_id : e.parent_entity_id//e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });
      this.nestedRelationships = uniqBy(this.nestedRelationships, (rel) => {
        return rel._id;
      });
    },
    async addTab() {
      this.refresh = true;
      if (this.form.label.trim() == "") {
        this.$message.warning("Please add Title");
        this.refresh = false;
        return;
      }
      if (this.form.type == "Tab") {
        if (this.editIndex == -1) {
          if (
            this.customization.tab_groups
              .map((e) => e.label)
              .includes(this.form.label.trim())
          ) {
            this.$message.warning("You can't add duplicate names for tabs");
            this.refresh = false;
            return;
          }
          let obj = {
            label: this.form.label.trim(),
            tabs: [],
          };
          if (this.customization.tab_groups.length == 0) {
            if (this.currentEntity.entity_type == "INDIVIDUAL") {
              let contactDetails = this.currentEntity.templates.find(
                (e) => e.templateInfo.type == "STANDARD"
              );
              this.contactDetailsId = contactDetails.templateInfo._id;
              let obj1 = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.form.label.trim(),
                feature_type: "TEMPLATE",
                feature_id: this.contactDetailsId,
                permissions: {
                  add: true,
                  assign: true,
                  total: true,
                  view: true,
                  redirect: true,
                  bulkUpdate: true,
                  rowEdit: true,
                  assign_type: "default_view",
                },
                addNewLabel: "Add New",
                assignLabel: "Assign",
              };
              obj.tabs.push(obj1);
            } else {
              let obj1 = {
                label: this.currentEntity.templates[0].templateInfo.name,
                type: "Feature",
                assignedTo: this.form.label.trim(),
                feature_type: "TEMPLATE",
                feature_id: this.currentEntity.templates[0].templateInfo._id,
                permissions: {
                  add: true,
                  assign: true,
                  total: true,
                  view: true,
                  redirect: true,
                  bulkUpdate: true,
                  rowEdit: true,
                  assign_type: "default_view",
                },
                addNewLabel: "Add New",
                assignLabel: "Assign",
              };
              obj.tabs.push(obj1);
            }
          }
          this.customization.tab_groups.push(obj);
        } else {
          if (
            this.customization.tab_groups
              .filter(
                (e) =>
                  this.customization.tab_groups.indexOf(e) !== this.editIndex
              )
              .map((e) => e.label)
              .includes(this.form.label.trim())
          ) {
            this.$message.warning("You can't add duplicate names for tabs");
            this.refresh = false;
            return;
          }
          this.customization.tab_groups[this.editIndex].label =
            this.form.label.trim();
        }
        this.clearForm();
      } else if (this.form.type == "Feature") {
        if (this.form.assignedTo == "") {
          this.$message.warning("Please assign this feature to specific tab");
          this.refresh = false;
          return;
        }
        if (this.form.feature_type == "") {
          this.$message.warning("Please select a feature type");
          this.refresh = false;
          return;
        }
        if (this.form.feature_id == "") {
          this.$message.warning("Please select a feature");
          this.refresh = false;
          return;
        }
        if (this.editIndex == -1) {
          let index = this.customization.tab_groups.findIndex(
            (e) => e.label == this.form.assignedTo
          );
          if (
            this.customization.tab_groups[index].tabs
              .map((e) => e.label)
              .includes(this.form.label.trim())
          ) {
            this.$message.warning(
              "You cannot add duplicate names for features"
            );
            this.refresh = false;
            return;
          }
          this.customization.tab_groups[index].tabs.push({
            label: this.form.label.trim(),
            feature_type: this.form.feature_type,
            feature_id: this.form.feature_id,
            permissions: {
              add: this.form.permissions.add,
              assign: this.form.permissions.assign,
              total: this.form.permissions.total,
              view: this.form.permissions.view,
              redirect: this.form.permissions.redirect,
              bulkUpdate: this.form.permissions.bulkUpdate,
              rowEdit: this.form.permissions.rowEdit,
              assign_type: this.form.permissions.assign_type,
            },
            type: "Feature",
            assignedTo: this.form.assignedTo.trim(),
            addNewLabel: this.form.addNewLabel.trim(),
            assignLabel: this.form.assignLabel.trim(),
            aggregations: this.form.aggregations,
          });
        } else {
          if (
            this.form.assignedTo ==
            this.customization.tab_groups[this.parentIndex].label
          ) {
            if (
              this.customization.tab_groups[this.parentIndex].tabs
                .filter(
                  (e) =>
                    this.customization.tab_groups[
                      this.parentIndex
                    ].tabs.indexOf(e) !== this.editIndex
                )
                .map((e) => e.label)
                .includes(this.form.label.trim())
            ) {
              this.$message.warning(
                "You cannot add duplicate names for features"
              );
              this.refresh = false;
              return;
            }
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].label = this.form.label.trim();
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].feature_type = this.form.feature_type;
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].feature_id = this.form.feature_id;
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].permissions = { ...this.form.permissions };
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].filter = this.form.filter;
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].addNewLabel = this.form.addNewLabel;
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].assignLabel = this.form.assignLabel;
            this.customization.tab_groups[this.parentIndex].tabs[
              this.editIndex
            ].aggregations = this.form.aggregations;
          } else {
            let index = this.customization.tab_groups.findIndex(
              (e) => e.label == this.form.assignedTo
            );
            if (
              this.customization.tab_groups[index].tabs
                .map((e) => e.label)
                .includes(this.form.label.trim())
            ) {
              this.$message.warning(
                "You cannot add duplicate names for features"
              );
              this.refresh = false;
              return;
            }
            this.customization.tab_groups[this.parentIndex].tabs.splice(
              this.editIndex,
              1
            );
            this.customization.tab_groups[index].tabs.push({
              label: this.form.label.trim(),
              feature_type: this.form.feature_type,
              feature_id: this.form.feature_id,
              permissions: {
                add: this.form.permissions.add,
                assign: this.form.permissions.assign,
                total: this.form.permissions.total,
              },
              filter: this.form.filter,
              aggregations: this.form.aggregations,
            });
          }
        }
        this.clearForm();
      }
      this.checkTabsConfigStatus();
      setTimeout(() => {
        this.refresh = false;
      });
    },
    clearForm() {
      (this.form = {
        label: "",
        type: "Tab",
        assignedTo: "",
        feature_type: "",
        feature_id: "",
        permissions: {
          add: true,
          assign: true,
          total: true,
          view: true,
          redirect: true,
          bulkUpdate: true,
          rowEdit: true,
          assign_type: "default_view",
        },
        addNewLabel: "Add New",
        assignLabel: "Assign",
        aggregations: [],
      }),
        (this.editIndex = -1);
      this.features = [];
    },
    editTab(data, node = {}) {
      this.refresh = true;
      this.form.label = data.label;
      this.form.type = Object.keys(data).includes("tabs") ? "Tab" : "Feature";
      this.form.assignedTo = node.parent.data.label
        ? node.parent.data.label
        : "";
      this.form.feature_type = data.feature_type ? data.feature_type : "";
      this.form.feature_id = data.feature_id ? data.feature_id : "";
      this.form.permissions.add = data?.permissions?.add
        ? data?.permissions?.add
        : false;
      this.form.permissions.assign = data?.permissions?.assign
        ? data?.permissions?.assign
        : false;
      this.form.permissions.total = data?.permissions?.total
        ? data?.permissions?.total
        : false;
      this.form.permissions.view = data?.permissions?.view
        ? data?.permissions?.view
        : false;
      this.form.permissions.redirect = data?.permissions?.redirect
        ? data?.permissions?.redirect
        : false;
      this.form.permissions.bulkUpdate = data?.permissions?.bulkUpdate
        ? data?.permissions?.bulkUpdate
        : false;
      this.form.permissions.rowEdit = data?.permissions?.rowEdit
        ? data?.permissions?.rowEdit
        : false;
      this.form.permissions.assign_type = data?.permissions?.assign_type
        ? data?.permissions?.assign_type
        : "default_view";
      this.form.addNewLabel = data?.addNewLabel ? data?.addNewLabel : "Add New";
      this.form.assignLabel = data?.assignLabel ? data?.assignLabel : "Assign";
      this.form.filter = data?.filter;
      this.form.aggregations = data?.aggregations ? data?.aggregations : [];
      if (this.form.type == "Tab") {
        this.editIndex = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == this.form.label.toString()
        );
      } else {
        this.parentIndex = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == node.parent.data.label.toString()
        );
        this.editIndex = node.parent.data.tabs.findIndex(
          (e) => e.label.toString() == this.form.label.toString()
        );
      }
      if (this.form.type == "Feature") {
        this.getFeatures(true);
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    deleteTab(data, node = {}) {
      this.refresh = true;
      if (Object.keys(data).includes("tabs")) {
        this.$confirm(
          "If you delete this tab, all the features in this tab will be delete too. Are you sure to delete the tab ?",
          "Warning",
          {
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          let index = this.customization.tab_groups.findIndex(
            (e) => e.label.toString() == data.label.toString()
          );
          this.customization.tab_groups.splice(index, 1);
          this.clearForm();
        });
      } else {
        this.$confirm("Are you sure delete this feature ?", "Warning", {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          let parentIndex = this.customization.tab_groups.findIndex(
            (e) => e.label.toString() == node.parent.data.label.toString()
          );
          let childIndex = node.parent.data.tabs.findIndex(
            (e) => e.label.toString() == data.label.toString()
          );
          this.customization.tab_groups[parentIndex].tabs.splice(childIndex, 1);
          this.clearForm();
        });
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    checkTemplateDisabled(temp) {
      if (temp) {
        for (let tab of this.customization.tab_groups) {
          if (
            this.editIndex !== -1 &&
            this.customization.tab_groups.indexOf(tab) == this.editIndex
          ) {
            continue;
          }
          let exists = tab.templates.find(
            (e) => e.toString() == temp.templateInfo._id.toString()
          );
          if (exists != undefined) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    checkRelationDisabled(rel) {
      if (rel) {
        for (let tab of this.customization.tab_groups) {
          if (
            this.editIndex !== -1 &&
            this.customization.tab_groups.indexOf(tab) == this.editIndex
          ) {
            continue;
          }
          let exists = tab.relationships.find(
            (e) => e.toString() == rel._id.toString()
          );
          if (exists != undefined) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    checkNestedRelationshipDisabled(rel) {
      if (rel) {
        for (let tab of this.customization.tab_groups) {
          if (
            this.editIndex !== -1 &&
            this.customization.tab_groups.indexOf(tab) == this.editIndex
          ) {
            continue;
          }
          let exists = tab.nested_relationships.find(
            (e) => e.toString() == rel._id.toString()
          );
          if (exists != undefined) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    checkOtherFeaturesDisabled(feature) {
      if (feature) {
        for (let tab of this.customization.tab_groups) {
          if (
            this.editIndex !== -1 &&
            this.customization.tab_groups.indexOf(tab) == this.editIndex
          ) {
            continue;
          }
          let exists = tab.others.find(
            (e) => e.toString() == feature.toString()
          );
          if (exists != undefined) {
            return true;
          }
        }
        return false;
      }
      return false;
    },
    async getFeatures(edit) {
      if (!edit) {
        this.form.feature_id = "";
      }
      this.features = [];
      let features;
      let feature;
      switch (this.form.feature_type) {
        case "TEMPLATE":
          features = this.currentEntity.templates.map(
            (e) =>
              e &&
              e.templateInfo && {
                label: e.templateInfo.name,
                id: e.templateInfo._id,
              }
          );
          break;
        case "RELATIONSHIP":
          features = this.relationships.map(
            (e) => e && { label: e.relationship_title, id: e._id }
          );
          feature = this.relationships.find(
            (e) => e._id == this.form.feature_id
          );
          await this.getEntityFilters(feature, edit);
          break;
        case "NESTED_RELATIONSHIP":
          features = this.nestedRelationships.map(
            (e) => e && { label: e.relationship_title, id: e._id }
          );
          break;
        case "OTHERS":
          features = [
            { label: "Files", id: "Files" },
            { label: "eSignatures", id: "eSignatures" },
            { label: "Notifications", id: "Emails" },
          ];
          if (this.currentEntity.entity_type == "BUSINESS") {
            features.push({ label: "Contacts", id: "Contacts" });
          }
      }
      this.features = [...this.features, ...features];
    },
    getFeatureDisabled(feature_id) {
      for (let e of this.customization.tab_groups) {
        if (e && e.tabs && e.tabs.length) {
          let exists = e.tabs.find((f) => f && f.feature_id == feature_id);
          let index = e.tabs.indexOf(exists);
          if (
            this.customization.tab_groups.indexOf(e) == this.parentIndex &&
            index == this.editIndex
          ) {
            exists = undefined;
          }
          if (exists !== undefined) {
            return true;
          }
        }
      }
      return false;
    },
    topTabDisabled(node) {
      if (node && node.data && Object.keys(node.data).includes("tabs")) {
        let index = this.customization.tab_groups.findIndex(
          (e) => e == node.data
        );
        if (index == 0) {
          return true;
        }
        return false;
      } else {
        let index = node.parent.data.tabs.indexOf(node.data);
        if (index == 0) {
          return true;
        }
        return false;
      }
    },
    bottomTabDisabled(node) {
      if (node && node.data && Object.keys(node.data).includes("tabs")) {
        let index = this.customization.tab_groups.findIndex(
          (e) => e == node.data
        );
        if (index == this.customization.tab_groups.length - 1) {
          return true;
        }
        return false;
      } else {
        let index = node.parent.data.tabs.indexOf(node.data);
        if (index == node.parent.data.tabs.length - 1) {
          return true;
        }
        return false;
      }
    },
    disableFeature(node) {
      if (node && node.data && !Object.keys(node.data).includes("tabs")) {
        if (
          node &&
          node.parent.data.tabs.indexOf(node.data) == 0 &&
          this.customization.tab_groups.indexOf(node.parent.data) == 0
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    moveToTop(data, node = {}) {
      this.refresh = true;
      if (Object.keys(data).includes("tabs")) {
        let index = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == data.label.toString()
        );
        if (index > 0) {
          let temp = this.customization.tab_groups[index];
          this.customization.tab_groups[index] =
            this.customization.tab_groups[index - 1];
          this.customization.tab_groups[index - 1] = temp;
          if (index == 1) {
            let obj = this.customization.tab_groups[1].tabs[0];
            obj.assignedTo = this.customization.tab_groups[0].label;
            if (this.currentEntity.entity_type == "INDIVIDUAL") {
              let stdObj = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.customization.tab_groups[0].label,
                feature_type: "TEMPLATE",
                feature_id: this.contactDetailsId,
              };
              this.customization.tab_groups[0].tabs.splice(0, 0, stdObj);
              this.customization.tab_groups[1].tabs.splice(0, 1);
            } else {
              this.customization.tab_groups[0].tabs.splice(0, 0, obj);
              this.customization.tab_groups[1].tabs.splice(0, 1);
            }
          }
        }
      } else {
        let childIndex = node.parent.data.tabs.findIndex(
          (e) => e.label.toString() == data.label.toString()
        );
        let parentIndex = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == node.parent.data.label.toString()
        );
        if (childIndex > 0) {
          let temp =
            this.customization.tab_groups[parentIndex].tabs[childIndex];
          this.customization.tab_groups[parentIndex].tabs[childIndex] =
            this.customization.tab_groups[parentIndex].tabs[childIndex - 1];
          this.customization.tab_groups[parentIndex].tabs[childIndex - 1] =
            temp;
        }
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    moveToBottom(data, node = {}) {
      this.refresh = true;
      if (Object.keys(data).includes("tabs")) {
        let index = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == data.label.toString()
        );
        if (index < this.customization.tab_groups.length - 1) {
          let temp = this.customization.tab_groups[index];
          this.customization.tab_groups[index] =
            this.customization.tab_groups[index + 1];
          this.customization.tab_groups[index + 1] = temp;
          if (index == 0) {
            let obj = this.customization.tab_groups[1].tabs[0];
            obj.assignedTo = this.customization.tab_groups[0].label;
            if (this.currentEntity.entity_type == "INDIVIDUAL") {
              let stdObj = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.customization.tab_groups[0].label,
                feature_type: "TEMPLATE",
                feature_id: this.contactDetailsId,
              };
              this.customization.tab_groups[0].tabs.splice(0, 0, stdObj);
              this.customization.tab_groups[1].tabs.splice(0, 1);
            } else {
              this.customization.tab_groups[0].tabs.splice(0, 0, obj);
              this.customization.tab_groups[1].tabs.splice(0, 1);
            }
          }
        }
      } else {
        let childIndex = node.parent.data.tabs.findIndex(
          (e) => e.label.toString() == data.label.toString()
        );
        let parentIndex = this.customization.tab_groups.findIndex(
          (e) => e.label.toString() == node.parent.data.label.toString()
        );
        if (childIndex < node.parent.data.tabs.length - 1) {
          let temp =
            this.customization.tab_groups[parentIndex].tabs[childIndex];
          this.customization.tab_groups[parentIndex].tabs[childIndex] =
            this.customization.tab_groups[parentIndex].tabs[childIndex + 1];
          this.customization.tab_groups[parentIndex].tabs[childIndex + 1] =
            temp;
        }
      }
      setTimeout(() => {
        this.refresh = false;
      });
    },
    resetTabs() {
      this.$confirm(
        "Do you want the tabs to be reset to default ?",
        "Warning",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.refresh = true;
        this.customization.tab_groups = [];
        this.refresh = false;
      });
    },
    async autoFillLabel() {
      let feature;
      this.form.aggregations = [];
      switch (this.form.feature_type) {
        case "TEMPLATE":
          feature = this.currentEntity.templates.find(
            (e) => e.templateInfo._id == this.form.feature_id
          );
          this.form.label = feature.templateInfo.name;
          break;
        case "RELATIONSHIP":
          feature = this.relationships.find(
            (e) => e._id == this.form.feature_id
          );
          this.form.label = feature.relationship_title;
          await this.getEntityFilters(feature);
          break;
        case "NESTED_RELATIONSHIP":
          feature = this.nestedRelationships.find(
            (e) => e._id == this.form.feature_id
          );
          this.form.label = feature.relationship_title;
          break;
        case "OTHERS":
          this.form.label = this.form.feature_id;
          break;
      }
    },
    changeSubTab() {
      if (this.previewSubTabs[this.previewActiveTab].length) {
        this.activeSubTab = this.previewSubTabs[this.previewActiveTab][0];
      }
    },
    updateDOM(row) {
      if (
        typeof row.visibility !== "undefined" ||
        typeof row.active !== "undefined"
      ) {
        if (!row.visibility) {
          row.isPrimary = false;
        }
        if (!row.active) {
          row.QAPrimary = false;
        }
        this.refresh = true;
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    async getEntityFilters(feature, edit = false) {
      if (feature != undefined) {
        let entity_id;
        if (feature.parent_entity_id == this.currentEntity._id) {
          entity_id = feature.child_entity_id;
        } else {
          entity_id = feature.parent_entity_id;
        }
        await this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: entity_id,
        });
        this.entityFilters = [];
        if (this.form.filter && !edit) {
          this.form.filter = "";
        }
        if (this.getAllEntityFilters) {
          this.entityFilters = this.getAllEntityFilters;
        }
      }
    },
  },
};
</script>
<style scoped>
.title-input {
  background-color: rgba(255, 255, 255, 0.35);
  color: #222222 !important;
  padding: 0 !important;
  border: none !important;
  font-size: 1.5em !important;
  box-shadow: none;
  align-items: stretch;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.centered-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  height: 85vh;
  text-align: center;
  /* Center the text inside */
}

.centered-div img,
.centered-div h1,
.centered-div span,
.centered-div el-button {
  margin: 5px 0;
  /* Add some vertical spacing between items */
}

.buttons {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
}

.button {
  width: 150px;
}

.center-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidemenu {
  height: 420px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.cover {
  margin-top: 90px;
}

.aggregations-table {
  margin-top: 5px;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
.formula-dialog {
  .form-left {
    min-height: 0px !important;
  }
  .variables-fields {
    display: flex !important;
    justify-content: space-between !important;
  }
  .operaters {
    text-align: center;
    margin-top: 1em;
    img {
      max-width: 25px;
    }
    .el-button-group {
      button {
        margin-bottom: 5px;

        @media (max-width: 600px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
.artboard {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d2d2d2;
  border-radius: 0px;
  padding: 0.25em;
  min-height: 60px;
  display: flex;
  align-items: center;
  overflow: scroll;
  width: 100%;
  background: #ffffff;
  .el-tag {
    margin-left: 0.5em;
  }
  .btn-variable {
    background-color: #000577;
    border-radius: 30px;
    color: #ffffff;
    border: #000577;
    padding: 10px 35px 10px 15px;
    position: relative;
    &.active {
      background-color: #dd5050 !important;
      box-shadow: 0 0 0 4px #efefef;
    }
    &:hover {
      box-shadow: 0 0 0 4px #f7f7f7;
    }
    img {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;
      opacity: 0.7;
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  .btn-operation {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 5px 6px;
    img {
      max-width: 15px;
    }
    &.active {
      border: 2px solid #ff3e3e !important;
      background-color: #f34423 !important;
    }
  }
  .text-button {
    font-size: 4em;
    line-height: 15px;
    span {
      position: relative;
      margin-top: -9px;
      display: block;
      color: #c4c4c4;
    }
  }
  .el-button + .el-button {
    margin: 0 5px;
  }
}
.formula-dialog .form-right .artboard .btn-variable {
  margin-top: 5px !important;
}
</style>
<style lang="scss">
.user-menu-container {
  background: #f5f7ff;
  border: 1px solid rgba(168, 171, 255, 0.3);
  border-radius: 20px;
  padding: 2em;
  position: relative;

  .menuPreviewButton {
    position: absolute;
    top: -1.5em;
    right: 15em;
    border-radius: 5px;
  }

  .menuSubmitButton {
    position: absolute;
    top: -1.5em;
    right: 2em;
    right: 6.5em;
    border-radius: 5px;
  }

  .menuDeleteButton {
    position: absolute;
    top: -1.5em;
    right: 1em;
    border-radius: 5px;
  }

  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 6em;

      &:hover {
        background-color: #f7f9fd;
      }
    }
  }

  .each-item {
    width: 25em;
    overflow: visible;
    margin-bottom: 1em;
    min-height: 5em;
    position: relative;
    z-index: 20;

    .el-card__body {
      padding-top: 0.75em;
    }

    &:last-child {
      margin-bottom: 0em;
    }

    & + .sub-item-1 {
      position: relative;
      margin-left: 3.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -2em;
        width: 2em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -2em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }

    & + .sub-item-2 {
      position: relative;
      margin-left: 5.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -4em;
        width: 4em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -4em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }
  }
}
</style>
